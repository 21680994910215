


















import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiCard from "@/components/common/AsiCard.vue";
import RegistrationForm from "@/components/RegistrationForm.vue";

@Component({
	components: {
		RegistrationForm, AsiCard
	}
})
export default class Home extends Vue {

}
