



















import Vue from 'vue';
import {Icon} from "@/helpers/icons";
import {Component} from "vue-property-decorator";
import AsiCard from "@/components/common/AsiCard.vue";
import {IAsiConfirmDialog} from "@/components/common/IAsiConfirmDialog";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn, AsiCard},
})
export default class AsiConfirmDialog extends Vue implements IAsiConfirmDialog {
	private icons = Icon;

	private dialog = false;
	private resolve = null as (Function | null);
	private reject = null as (Function | null);

	private title = null as (string | null);
	private message = null as (string | null);
	private icon = null as (string | null);

	public openDialog(title: string | null = null, message: string | null = null, icon: string = Icon.confirm): Promise<boolean> {
		this.dialog = true;
		this.title = title ?? this.$t('confirm.title').toString();
		this.message = message ?? this.$t('confirm.message').toString();
		this.icon = icon;

		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	private agree(): void {
		if (null !== this.resolve) {
			this.resolve(true);
		}
		this.dialog = false;
	}

	private cancel(): void {
		if (null !== this.resolve) {
			this.resolve(false);
		}
		this.dialog = false;
	}

}
