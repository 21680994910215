






import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiAutocomplete from "./AsiAutocomplete";
import {getNames} from 'i18n-iso-countries';
import {isEmpty} from "@/helpers/functions_asi";

@Component({
	components: {AsiAutocomplete}
})
export default class AsiCountryAutocompleteInput extends Vue {

	@Prop({default: null})
	public value!: string | string[] | null;

	@Prop({type: String, default: null})
	public label!: string | null;

	@Prop({type: Array, default: () => []})
	public rules!: (string | null | number | object | unknown | boolean)[];

	@Prop({type: Boolean, default: false})
	public clearable!: boolean;

	@Prop({type: Boolean, default: false})
	public multiple!: boolean;

	private get countries(): { text: string; value: string }[] {
		const locale = this.$i18n.locale;
		const lang = locale.substr(0, 2).toUpperCase();
		const codes = getNames(lang);

		return Object.keys(codes).map(k => {
			return {
				text: codes[k],
				value: k,
			} as { text: string; value: string };
		});
	}

	private get finalLabel(): string {
		return !isEmpty(this.label) && this.label !== null ? this.label : this.$t('uiTerms.country').toString();
	}

	@Emit('input')
	public input(event: Event): Event {
		return event;
	}

	@Emit('change')
	public change(event: Event): Event {
		return event;
	}

}
