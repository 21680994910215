














import Vue from 'vue';
import AsiTextField from "@/components/common/AsiTextField";
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import {isEmpty} from "@/helpers/functions_asi";

@Component({
	components: {AsiTextFieldSimple, AsiTextField},
})
export default class AsiDatePicker extends Vue {

	@Prop({type: String, required: true})
	public label!: string;

	@Prop({default: null})
	public value!: string | string[] | null;

	@Prop({type: String})
	public maxDate!: string;

	@Prop({type: String})
	public minDate!: string;

	@Prop({type: Boolean, default: false})
	public clearable!: boolean;

	@Prop({type: String, default: ''})
	public activePickerView!: string;

	@Prop({type: Boolean, default: false})
	public simple!: boolean;

	@Prop({type: Array, default: () => []})
	public rules!: (string | null | number | object | unknown | boolean)[];

	@Prop({type: Boolean, default: false})
	public range!: boolean;

	@Prop({type: Boolean, default: true})
	public autoClose!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	protected menu: boolean = false;
	protected valueInternal: any = null;

	private get locale(): string {
		return this.$i18n.locale;
	}

	private get textValue(): string | null {
		if (this.valueInternal === null) return null;

		if (Array.isArray(this.valueInternal)) {
			if (this.valueInternal.length === 0) {
				return null;
			} else if (this.valueInternal.length === 1) {
				return this.formatDate(this.valueInternal[0]);
			} else {
				return this.$t('uiTerms.dateRangeValues', {
					from: this.formatDate(this.valueInternal[0]),
					to: this.formatDate(this.valueInternal[1]),
				}).toString();
			}
		} else {
			return isEmpty(this.valueInternal) ? null : this.formatDate(this.valueInternal)
		}
	}

	private get hasSelectedDates(): boolean {
		return Array.isArray(this.valueInternal) ? this.valueInternal.length > 0 : !isEmpty(this.valueInternal);
	}

	public mounted(): void {
		this.updateValueInternal();
	}

	private sortValueInternal(): void {
		if (Array.isArray(this.valueInternal) && this.valueInternal.length > 1) {
			this.valueInternal = this.valueInternal.sort((d1: string, d2: string) => d1.localeCompare(d2));
		}
	}

	@Emit('change')
	public change(): string | string[] | null {
		this.handleValueChanged();
		return this.valueInternal;
	}

	@Emit('input')
	public input(): string | string[] | null {
		this.handleValueChanged();
	    return this.valueInternal;
	}

	private handleValueChanged(): void {
		//auto close
		if (this.autoClose && this.valueInternal !== null) {
			if (this.range) {
				if (Array.isArray(this.valueInternal) && this.valueInternal.length === 2) {
					this.menu = false;
				}
			} else {
				if ((Array.isArray(this.valueInternal) && this.valueInternal.length === 1) || !isEmpty(this.valueInternal)) {
					this.menu = false;
				}
			}
		}

		this.sortValueInternal();
	}

	@Watch('value', {deep: true})
	private onValueChanged(): void {
		this.updateValueInternal();
	}

	@Watch('menu')
	private onMenuChanged(value: boolean): void {
		if (value && !this.hasSelectedDates && !isEmpty(this.activePickerView)) {
			setTimeout(() => {
				const picker = this.$refs.picker as unknown as { activePicker: string };
				picker.activePicker = this.activePickerView.toUpperCase();
			}, 100);
		}
	}

	private clear(): void {
		this.valueInternal = null;
		this.input();
	}

	private updateValueInternal(): void {
		if (Array.isArray(this.value)) {
			this.valueInternal = this.value.filter(v => v !== null);
			this.sortValueInternal();
			if (this.valueInternal.length > 2) {
				this.valueInternal = this.valueInternal.slice(0, 2);
			}
		} else {
			this.valueInternal = this.value;
		}
	}

	private formatDate(value: string | null): string {
		if (isEmpty(value) || value === null) return '';
		return this.$d(new Date(value), 'short').toString();
	}

}
