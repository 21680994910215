






import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component
export default class AsiAlert extends Vue {

	@Prop({type: String, required: true})
	public type!: string;

	@Prop({type: String, default: null})
	public icon!: string | null;

}
