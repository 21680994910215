
































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {Icon} from "@/helpers/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn, AsiCard}
})
export default class RegistrationSubmitDialog extends Vue {

	@Prop({type: Boolean, default: false})
	public open!: boolean;

	@Prop({type: Number, default: null})
	public progress!: number | null;

	@Prop({type: Boolean, default: false})
	public working!: boolean;

	private icons = Icon;

	private get isIndeterminate(): boolean {
		return this.working && this.progress === null;
	}

	private get progressCleaned(): number {
		if (!this.working) return 100;
		if (this.progress === null || this.progress < 0) return 0;
		if (this.progress > 100) return 100;

		return Math.ceil(this.progress);
	}

	@Emit('requestClose')
	public requestClose(event: Event): Event {
		return event;
	}

}
