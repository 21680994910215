import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import i18n from "@/i18n";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'pageTitles.home',
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
  let title = i18n.t('pageTitles.application').toString();
  if (to.meta && to.meta.title) {
    title = i18n.t(to.meta.title).toString() + ' | ' + title;
  }
  document.title = title;

  next();
});

export default router
