




























































































































































































































































































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import AsiTextField from "@/components/common/AsiTextField";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import AsiDatePicker from "@/components/common/AsiDatePicker.vue";
import AsiCountryAutocompleteInput from "@/components/common/AsiCountryAutocompleteInput.vue";
import AsiSelect from "@/components/common/AsiSelect";
import AsiTextarea from "@/components/common/AsiTextarea";
import {Icon} from "@/helpers/icons";
import {EmploymentType, Salutation} from "@/helpers/constants";
import {enumToSelectItems, toISODateString} from "@/helpers/functions_asi";
import AsiFileInput from "@/components/common/AsiFileInput";
import RegistrationFormFileGroup from "@/components/RegistrationFormFileGroup.vue";
import RegistrationCreate from "@/models/registration/RegistrationCreate";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import RegistrationSubmitDialog from "@/components/RegistrationSubmitDialog.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";

@Component({
	components: {
		AsiAlert,
		AsiFadeTransition,
		RegistrationSubmitDialog,
		AsiConfirmDialog,
		RegistrationFormFileGroup,
		AsiFileInput,
		AsiTextarea,
		AsiSelect,
		AsiCountryAutocompleteInput,
		AsiDatePicker, AsiRadioGroup, AsiCard, AsiTextField, AsiCheckbox, AsiBtn
	}
})
export default class RegistrationForm extends Vue {

	private static readonly CONFIRM_SUBMIT = 'Möchten sie die Anmeldung jetzt absenden?';
	private static readonly CONFIRM_RESET = 'Möchten Sie die Daten löschen und neu beginnen?';

	protected loading: boolean = false;
	protected valid: boolean = false;
	protected errors: string[] = [];
	protected icons = Icon;
	protected salutations: { text: string; value: number }[] = [];
	protected employmentTypes: { text: string; value: number }[] = [];
	protected model: RegistrationCreate = new RegistrationCreate();
	protected submitDialogOpen: boolean = false;
	protected submitProgress: number | null = null;
	protected submitWorking: boolean = false;
	protected snackbarVisible: boolean = false;
	protected snackbarMessage: string | null = null;
	protected snackbarColor: string | null = null;

	public get isEmployee(): boolean {
		return this.model.employmentType !== EmploymentType.selfEmployed;
	}

	public get isSelfEmployed(): boolean {
		return this.model.employmentType === EmploymentType.selfEmployed;
	}

	public get titleFieldsetEmployer(): string {
		return this.isEmployee ? 'Adresse der Praxis' : 'Adresse der Praxis des / des Praxisausbildner-s/in';
	}

	public get labelTutor(): string {
		return this.isEmployee ? 'Fachperson' : 'Name Praxisausbildner';
	}

	public get maxBirthdayDate(): string {
		return toISODateString(new Date());
	}

	public created(): void {
		this.salutations = enumToSelectItems(Salutation, true);
		this.employmentTypes = enumToSelectItems(EmploymentType, true);
	}

	protected manualSubmit(): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog(null, RegistrationForm.CONFIRM_SUBMIT).then((res: boolean) => {
			if (!res) return;
			this.submit();
		});
	}

	protected submit(): void {
		this.submitProgress = 0;
		this.submitWorking = true;
		this.submitDialogOpen = true;
		this.model.beforeSend();

		const formData = new FormData();
		for (const key in this.model) {
			/* @ts-ignore */
			const val = this.model[key];
			// omit files
			if (val instanceof File) {
				continue;
			}
			// omit files
			if (Array.isArray(val)) {
				if (val.some(v => v instanceof File)) {
					continue;
				}
			}

			if (null !== val) {
				formData.append(key, val);
			}
		}

		this.model.filesConfirmationSelfEmployment.forEach(file => formData.append('filesConfirmationSelfEmployment', file as Blob, file?.name));
		this.model.filesConfirmationTutor.forEach(file => formData.append('filesConfirmationTutor', file as Blob, file?.name));
		this.model.filesDiploma.forEach(file => formData.append('filesDiploma', file as Blob, file?.name));
		this.model.filesDiplomaTutor.forEach(file => formData.append('filesDiplomaTutor', file as Blob, file?.name));
		this.model.filesEducationContract.forEach(file => formData.append('filesEducationContract', file as Blob, file?.name));
		this.model.filesPensum.forEach(file => formData.append('filesPensum', file as Blob, file?.name));
		this.model.filesVita.forEach(file => formData.append('filesVita', file as Blob, file?.name));
		this.model.filesVocationalTraining.forEach(file => formData.append('filesVocationalTraining', file as Blob, file?.name));
		this.model.filesDiabetesSeminar.forEach(file => formData.append('filesDiabetesSeminar', file as Blob, file?.name));

		this.$axios.post('/registration', formData, {
			onUploadProgress: (event: ProgressEvent) => {
				this.submitProgress = Math.ceil((event.loaded * 100) / event.total);
			}
		})
			.then(() => {
				this.model = new RegistrationCreate();
				this.reset();
				this.showSuccessSnackbar();
			})
			.catch(() => {
				this.showFailSnackbar();
				this.submitDialogOpen = false;
			})
			.finally(() => {
				this.submitProgress = null;
				this.submitWorking = false;
			});
	}

	protected manualReset(): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog(null, RegistrationForm.CONFIRM_RESET).then((res: boolean) => {
			if (!res) return;
			this.reset();
		});
	}

	protected reset(): void {
		this.model.reset();
		const form = this.$refs.form as HTMLFormElement;
		if (form !== null) {
			form.resetValidation();
		}

		this.submitProgress = null;
		this.valid = false;
		this.errors = [];
	}

	protected showSuccessSnackbar(): void {
		this.snackbarMessage = 'Vielen Dank für Ihre Anmeldung. Wir haben die Daten erhalten.';
		this.snackbarColor = 'success';
		this.snackbarVisible = true;
	}

	protected showFailSnackbar(): void {
		this.snackbarMessage = 'Beim Senden Ihrer Anmeldung ist ein Fehler unterlaufen. Bitte versuchen Sie es erneut.';
		this.snackbarColor = 'error';
		this.snackbarVisible = true;
	}

}
