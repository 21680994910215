











import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component
export default class AsiAvatar extends Vue {

	@Prop({type: Number, default: 64})
	public size!: number;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: String, default: null})
	public imageUrl!: string | null;

	@Prop({type: String, default: 'primary'})
	public bgColor!: string;

	@Prop({type: String, default: 'secondary'})
	public iconColor!: string;

	@Prop({type: String, default: null})
	public vOn!: string | null;

	@Prop({type: String, default: null})
	public vBind!: string | null;

}
