import {ValidationRule} from "@/declarations/ValidationRule";
import {ValidationRuleInput} from "@/declarations/ValidationRuleInput";
import i18n from "@/i18n";

export default abstract class Validator {

	private readonly labelInternal: string;

	protected constructor(label: string, translateLabel: boolean = true) {
		this.labelInternal = translateLabel ? i18n.t(label).toString() : label;
	}

	public validationRule(): ValidationRule {
		return (val: ValidationRuleInput) => this.validate(val);
	}

	protected success(): boolean {
		return true;
	}

	protected fail(reason: number | null = null): string {
		return this.validationFailedMessage(this.labelInternal, reason);
	}

	protected abstract validate(value: ValidationRuleInput): boolean | string;
	protected abstract validationFailedMessage(label: string, reason: number | null): string;

}
