import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import {EmploymentType, Salutation} from "@/helpers/constants";
import {RangeValidator} from "@/validators/RangeValidator";
import {enumToArray} from "@/helpers/functions_asi";
import RequiredValidator from "@/validators/RequiredValidator";
import EmailValidator from "@/validators/EmailValidator";
import {FileValidator} from "@/validators/FileValidator";

export default class RegistrationCreate implements IFormModel {

	public static readonly FILE_ACCEPT: string = '.pdf,.jpg,.jpeg,.png,.zip,.7z,.rar,.doc,.docx,.xls,.xlsx,.ppt,.pptx';

	public salutation: string | null = null;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public street: string | null = null;
	public zip: string | null = null;
	public city: string | null = null;
	public birthday: string | null = null;
	public hometown: string | null = null;
	public nationality: string | null = null;

	public phone: string | null = null;
	public email: string | null = null;

	public employmentType: EmploymentType | null = null;

	public officeName: string | null = null;
	public officeStreet: string | null = null;
	public officeZip: string | null = null;
	public officeCity: string | null = null;
	public officePhone: string | null = null;
	public officeEmail: string | null = null;
	public officeTutor: string | null = null;

	public ownCompanyName: string | null = null;
	public ownCompanyStreet: string | null = null;
	public ownCompanyZip: string | null = null;
	public ownCompanyCity: string | null = null;
	public ownCompanyPhone: string | null = null;
	public ownCompanyEmail: string | null = null;
	public ownCompanyTutor: string | null = null;

	public filesVita: File[] = [];
	public filesDiploma: File[] = [];
	public filesEducationContract: File[] = [];
	public filesDiplomaTutor: File[] = [];
	public filesConfirmationTutor: File[] = [];
	public filesVocationalTraining: File[] = [];
	public filesDiabetesSeminar: File[] = [];
	public filesPensum: File[] = [];
	public filesConfirmationSelfEmployment: File[] = [];

	public remarks: string | null = null;
	public newsletter: boolean = false;
	public toc: boolean = false;

	public beforeSend(): void {
		if (this.employmentType === EmploymentType.selfEmployed) return;

		this.ownCompanyName = null;
		this.ownCompanyStreet = null;
		this.ownCompanyZip = null;
		this.ownCompanyCity = null;
		this.ownCompanyPhone = null;
		this.ownCompanyEmail = null;
	}

	public reset(): void {
		this.salutation = null;
		this.firstname = null;
		this.lastname = null;
		this.street = null;
		this.zip = null;
		this.city = null;
		this.birthday = null;
		this.hometown = null;
		this.nationality = null;

		this.phone = null;
		this.email = null;

		this.employmentType = null;

		this.officeName = null;
		this.officeStreet = null;
		this.officeZip = null;
		this.officeCity = null;
		this.officePhone = null;
		this.officeEmail = null;
		this.officeTutor = null;

		this.ownCompanyName = null;
		this.ownCompanyStreet = null;
		this.ownCompanyZip = null;
		this.ownCompanyCity = null;
		this.ownCompanyPhone = null;
		this.ownCompanyEmail = null;
		this.ownCompanyTutor = null;

		this.filesVita = [];
		this.filesDiploma = [];
		this.filesEducationContract = [];
		this.filesDiplomaTutor = [];
		this.filesConfirmationTutor = [];
		this.filesPensum = [];
		this.filesConfirmationSelfEmployment = [];
		this.filesVocationalTraining = [];
		this.filesDiabetesSeminar = [];

		this.remarks = null;
		this.newsletter = false;
		this.toc = false;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return [
					new RequiredValidator('Anrede', false).validationRule(),
					new RangeValidator('Anrede', false, enumToArray(Salutation).map(e => e.value)).validationRule(),
				];
			case 'lastname':
				return [new RequiredValidator('Nachname', false).validationRule()];
			case 'firstname':
				return [new RequiredValidator('Vorname', false).validationRule()];
			case 'street':
				return [new RequiredValidator('Strasse / Nr', false).validationRule()];
			case 'zip':
				return [new RequiredValidator('PLZ', false).validationRule()];
			case 'city':
				return [new RequiredValidator('Ort', false).validationRule()];
			case 'birthday':
				return [new RequiredValidator('Geburtsdatum', false).validationRule()];
			case 'hometown':
				return [new RequiredValidator('Heimatort', false).validationRule()];
			case 'nationality':
				return [new RequiredValidator('Nationalität', false).validationRule()];
			case 'phone':
				return [new RequiredValidator('Telefonnummer privat', false).validationRule()];
			case 'email':
				return [
					new RequiredValidator('E-Mail privat', false).validationRule(),
					new EmailValidator('E-Mail privat', false).validationRule(),
				];
			case 'employmentType':
				return [new RequiredValidator('Anstellungsverhältnis', false).validationRule()];
			case 'officeName':
				return [new RequiredValidator('Name der Praxis', false).validationRule()];
			case 'officeStreet':
				return [new RequiredValidator('Strasse / Nr', false).validationRule()];
			case 'officeZip':
				return [new RequiredValidator('PLZ', false).validationRule()];
			case 'officeCity':
				return [new RequiredValidator('Ort', false).validationRule()];
			case 'officeTutor':
				return [new RequiredValidator('Fachperson / Name Praxisausbildner', false).validationRule()];
			case 'officePhone':
				return [new RequiredValidator('Telefonnummer Praxis', false).validationRule()];
			case 'officeEmail':
				return [
					new RequiredValidator('E-Mail Praxis', false).validationRule(),
					new EmailValidator('E-Mail Praxis', false).validationRule(),
				];
			case 'ownCompanyName':
				return [new RequiredValidator('Name der eigenen Praxis', false).validationRule()];
			case 'ownCompanyStreet':
				return [new RequiredValidator('Strasse / Nr', false).validationRule()];
			case 'ownCompanyZip':
				return [new RequiredValidator('PLZ', false).validationRule()];
			case 'ownCompanyCity':
				return [new RequiredValidator('Ort', false).validationRule()];
			case 'ownCompanyTutor':
				return [new RequiredValidator('Fachperson / Name Praxisausbildner', false).validationRule()];
			case 'ownCompanyPhone':
				return [new RequiredValidator('Telefonnummer eigene Praxis', false).validationRule()];
			case 'ownCompanyEmail':
				return [
					new RequiredValidator('E-Mail eigene Praxis', false).validationRule(),
					new EmailValidator('E-Mail eigene Praxis', false).validationRule(),
				];
			case 'toc':
				return [new RequiredValidator('Allgemeine Geschäftsbedingungen', false).validationRule()];

			case 'filesVita':
				return [new FileValidator('Lebenslauf', false, 10 * 1024 * 1024).validationRule()];

		}

		return [];
	}

}
