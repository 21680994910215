import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VAutocomplete} from "vuetify/lib";

const autocomplete = VAutocomplete as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiAutocomplete',
	extends: autocomplete,
	props: {
		hideDetails: {
			type: String,
			default: 'auto',
		},
		filled: {
			type: Boolean,
			default: true,
		},
		simple: {
			type: Boolean,
			default: false,
		},
	},
});
