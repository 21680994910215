import Vue from 'vue'
import VueI18n, {LocaleMessages} from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages: LocaleMessages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

const dateTimeFormats = {
	'en-US': {
		short: {
			year: 'numeric', month: 'short', day: '2-digit'
		},
		long: {
			year: 'numeric', month: 'numeric', day: '2-digit',
			weekday: 'short', hour: 'numeric', minute: 'numeric',
			hour12: false,
		},
		timeShort: {
			hour: '2-digit', minute: '2-digit', hour12: false,
		},
		dayNumeric: {
			day: '2-digit',
		},
		dayMonth: {
			day: '2-digit', month: 'short',
		},
		dayMonthLong: {
			day: '2-digit', month: 'long',
		},
		dayMonthYear: {
			day: '2-digit', month: 'short', year: 'numeric',
		},
		dayMonthYearLong: {
			day: '2-digit', month: 'long', year: 'numeric',
		},
		month: {
			month: 'short',
		},
		monthNumeric: {
			month: '2-digit',
		},
		monthYear: {
			month: 'short', year: 'numeric',
		},
		monthYearLong: {
			month: 'long', year: 'numeric',
		},
		yearNumeric: {
			year: 'numeric',
		},
		weekDay: {
			weekday: 'short',
		},
		weekDayLong: {
			weekday: 'long',
		},
	},
	'de-CH': {
		short: {
			year: 'numeric', month: 'short', day: '2-digit'
		},
		long: {
			year: 'numeric', month: 'numeric', day: '2-digit',
			weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
		},
		timeShort: {
			hour: '2-digit', minute: '2-digit', hour12: false,
		},
		dayNumeric: {
			day: '2-digit',
		},
		dayMonth: {
			day: '2-digit', month: 'short',
		},
		dayMonthLong: {
			day: '2-digit', month: 'long',
		},
		dayMonthYear: {
			day: '2-digit', month: 'short', year: 'numeric',
		},
		dayMonthYearLong: {
			day: '2-digit', month: 'long', year: 'numeric',
		},
		month: {
			month: 'short',
		},
		monthNumeric: {
			month: '2-digit',
		},
		monthYear: {
			month: 'short', year: 'numeric',
		},
		monthYearLong: {
			month: 'long', year: 'numeric',
		},
		yearNumeric: {
			year: 'numeric',
		},
		weekDay: {
			weekday: 'short',
		},
		weekDayLong: {
			weekday: 'long',
		},
	},
};

export default new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'de-CH',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
	messages: loadLocaleMessages(),
	dateTimeFormats: dateTimeFormats,
})
