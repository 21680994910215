import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import i18n from "@/i18n";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		t: (key, ...params) => i18n.t(key, params).toString(),
	},
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#fcdc00',
                secondary: '#000000',
                accent: '#43b02a',
                error: '#FF5252',
                info: '#2196F3',
                success: '#43b02a',
                warning: '#f7941e',

                'wbzo-yellow': '#fcdc00',
            },
        }
    },
});
