import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VRadioGroup} from "vuetify/lib";

const radioGroup = VRadioGroup as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiRadioGroup',
	extends: radioGroup,
	props: {
		hideDetails: {
			type: String,
			default: 'auto',
		},
	},
});
