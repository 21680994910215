import i18n from "@/i18n";
import {getName} from "i18n-iso-countries";

export function call(nr: string): void {
	const finalNumber = nr.replace(/\s/g, nr);
	window.open('tel:' + finalNumber);
}

export function mail(email: string): void {
	location.href = 'mailto:' + email;
}

export function openUrl(url: string): void {
	const w = window.open(url, '_blank');
	if (w !== null) {
		w.focus();
	}
}

export function isEmpty(str: string | null | undefined, ignoreWhitespaces: boolean = true): boolean {
	if (str === null || str === undefined) return true;
	if (ignoreWhitespaces) {
		str = str.replace(/\s/, '');
	}

	return str.length === 0;
}

/**
 * Sorts dates
 *
 * @param d1 first date
 * @param d2 second date
 * @param asc whether or not to sort ascending
 */
export function sortDates(d1: Date, d2: Date, asc = true): number {
	const stamp1 = d1.getTime();
	const stamp2 = d2.getTime();

	if (stamp1 < stamp2) {
		return asc ? -1 : 1;
	} else if (stamp1 > stamp2) {
		return asc ? 1 : -1;
	} else {
		return 0;
	}
}

export function getFirstOfMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastOfMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getMonday(date: Date): Date {
	const day = date.getDay();
	const diff = date.getDate() - day + (day === 0 ? -6 : 1);
	return new Date(date.setDate(diff));
}

export function getWeekNumber(date: Date): number {
	// duplicate date
	const dateInternal = new Date(date);
	// ISO week date weeks start on Monday, so correct the day number
	const nDay = (dateInternal.getDay() + 6) % 7;
	// ISO 8601 states that week 1 is the week with the first Thursday of that year
	// Set the target date to the Thursday in the target week
	dateInternal.setDate(dateInternal.getDate() - nDay + 3);
	// Store the millisecond value of the target date
	const n1stThursday = dateInternal.valueOf();
	// Set the target to the first Thursday of the year
	// First, set the target to January 1st
	dateInternal.setMonth(0, 1);
	// Not a Thursday? Correct the date to the next Thursday
	if (dateInternal.getDay() !== 4) {
		dateInternal.setMonth(0, 1 + ((4 - dateInternal.getDay()) + 7) % 7);
	}
	// The week number is the number of weeks between the first Thursday of the year
	// and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
	return 1 + Math.ceil((n1stThursday - dateInternal.valueOf()) / 604800000);
}

export function isSameDay(date1: Date, date2: Date): boolean {
	return date1.getFullYear() === date2.getFullYear()
		&& date1.getMonth() === date2.getMonth()
		&& date1.getDate() === date2.getDate();
}

export function isToday(date: Date): boolean {
	return isSameDay(date, new Date());
}

export function getISO8601DayIndex(date: Date): number {
	const day = date.getDay();
	return day === 0 ? 7 : day;
}

/**
 * Creates the date part of an ISO date time string (Example: 2011-10-05)
 *
 * @param date
 */
export function toISODateString(date: Date): string {
	const year = i18n.d(date, 'yearNumeric');
	const month = i18n.d(date, 'monthNumeric');
	const day = i18n.d(date, 'dayNumeric');

	return `${year}-${month}-${day}`;
}

/**
 * Creates an iso date time string
 * @param date
 */
export function toISODateTimeString(date: Date): string {
	return date.toISOString();
}

export function formatDuration(durationMinutes: number): string {
	const hours = Math.floor(durationMinutes / 60);
	const minutes = (durationMinutes % 60);

	let ret = hours.toFixed(0) + ' ' + i18n.tc('uiTerms.hours', hours);
	if (minutes !== 0) {
		ret = ret + ' ' + minutes.toFixed(0) + ' ' + i18n.tc('uiTerms.minutes', minutes);
	}
	return ret;
}

/**
 * Checks whether or not a date range is active
 *
 * @param from from date
 * @param to to date
 */
export function isDateRangeActive(from: Date, to: Date | null): boolean {
	const now = new Date();

	if (from > now) {
		return false;
	}
	if (to !== null && to < now) {
		return false;
	}

	return true;
}

export function countryName(isoAbbr: string | null, lang: string | null = null): string | null {
	if (isoAbbr === null) return null;

	if (lang === null) {
		const locale = i18n.locale;
		lang = locale.substr(0, 2).toUpperCase();
	} else {
		lang = lang.toUpperCase();
	}

	return getName(isoAbbr, lang) ?? null;
}

export function formatCurrency(amount: string | number | null, currency: string = 'CHF'): string | null {
	if (amount === null) return null;
	if (typeof amount === 'string') {
		amount = parseFloat(amount);
		if (isNaN(amount)) return 'NaN';
	}

	return new Intl.NumberFormat(i18n.locale, {
		style: 'currency',
		currency: currency.toLocaleUpperCase(),
	}).format(amount);
}

export function roundToFiveCents(number: number): number {
	return Math.round(number * 20) / 20;
}

export function assertNumber(value: string | number | null): number | null {
	if (value === null) return null;
	if (typeof value === 'string') {
		if (isEmpty(value)) return null;
		const converted = parseFloat(value);
		return isNaN(converted) ? null : converted;
	}

	value = value as number;
	return value;
}

export function enumToArray(e: any): { key: string; value: number }[] {
	return Object.keys(e)
		.filter(v => isNaN(Number(v)))
		.map((key: string) => {
			return {
				key: key,
				value: e[key],
			};
		});
}

export function enumToSelectItems(e: any, translateTexts: boolean | null = false): { text: string; value: number }[] {
	return enumToArray(e).map(kvp => {
		return {
			text: translateTexts ? i18n.t('constants.' + kvp.key).toString() : kvp.key,
			value: kvp.value,
		};
	});
}

export function enumTextFromValue(e: any, v: number | null, translateText: boolean | null = false): string | null {
	if (v === null) {
		return null;
	}

	const entry = enumToArray(e).find(o => o.value === v);
	if (entry === undefined) {
		return null;
	}

	return translateText ? i18n.t('constants.' + entry.key).toString() : entry.key;
}
