import {ValidationRuleInput} from "@/declarations/ValidationRuleInput";
import i18n from "@/i18n";
import Validator from "@/validators/Validator";

export default class RequiredValidator extends Validator {

	public constructor(label: string, translateLabel: boolean = true) {
		super(label, translateLabel);
	}

	protected validate(value: ValidationRuleInput): boolean | string {
		if (!!value || value === 0) {
			return this.success();
		}

		return this.fail();
	}

	protected validationFailedMessage(label: string, reason: number | null = null): string {
		return i18n.t('validation.isRequired', {field: label}).toString();
	}

}
