













import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";

@Component
export default class AsiBtn extends Vue {

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: String, default: null})
	public color!: null;

	@Prop({type: Boolean, default: false})
	public xLarge!: boolean;

	@Prop({type: Boolean, default: false})
	public large!: boolean;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public xSmall!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: false;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public preventIconMode!: boolean;

	@Prop({type: String, default: null})
	public href!: string;

	@Prop({type: String, default: null})
	public target!: string;

	private get hasIcon(): boolean {
		return this.icon !== null;
	}

	private get hasContent(): boolean {
		return (this.$slots.default ?? []).length > 0;
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

}
