export enum Icon {

	yes = 'mdi-check',
	no = 'mdi-close',
	alert = 'mdi-alert',
	confirm = 'mdi-alert',
	list = 'mdi-format-list-bulleted-square',
	edit = 'mdi-pencil',
	wait = 'mdi-timer-sand',
	upload = 'mdi-file-upload',
	close = 'mdi-close',
	phone = 'mdi-phone',
	email = 'mdi-email',
	web = 'mdi-web',
	legal = 'mdi-web',
	toc = 'mdi-web',

}
