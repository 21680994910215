




















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiFileInput from "./common/AsiFileInput";
import {Icon} from "@/helpers/icons";
import RegistrationCreate from "@/models/registration/RegistrationCreate";

@Component({
	components: {AsiFileInput}
})
export default class RegistrationFormFileGroup extends Vue {

	@Prop({type: String, required: true})
	public title!: string;

	@Prop({type: String, required: true})
	public desc!: string;

	@Prop({type: String, default: RegistrationCreate.FILE_ACCEPT})
	public accept!: string | null;

	@Prop()
	public value!: any;

	private icons = Icon;

	private get hasFiles(): boolean {
		return (Array.isArray(this.value) && this.value.length > 0) || (!Array.isArray(this.value) && this.value !== null);
	}

	private get iconColor(): string | null {
		return this.hasFiles ? 'success' : null;
	}

	@Emit('input')
	public input(event: Event): Event {
		return event;
	}

}
