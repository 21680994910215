import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import {LocaleData, registerLocale} from "i18n-iso-countries";

const countriesEn: LocaleData = require("i18n-iso-countries/langs/en.json") as LocaleData;
const countriesDe: LocaleData = require("i18n-iso-countries/langs/de.json") as LocaleData;
registerLocale(countriesEn);
registerLocale(countriesDe);

Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
