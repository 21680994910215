






import Vue from 'vue';
import {Component} from "vue-property-decorator";

@Component
export default class AsiFadeTransition extends Vue {

}
