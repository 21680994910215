






























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiAvatar from "@/components/common/AsiAvatar.vue";

@Component({
	components: {AsiAvatar}
})
export default class AsiCard extends Vue {

	@Prop({type: String, default: ''})
	public titleBgColor!: string;

	@Prop({type: String, default: ''})
	public title!: string;

	@Prop({type: Boolean, default: false})
	public wrapTitle!: boolean;

	@Prop({type: String})
	public subtitle!: string;

	@Prop({type: Boolean, default: false})
	public wrapSubtitle!: boolean;

	@Prop({type: String})
	public icon!: string;

	@Prop({type: String, default: 'white'})
	public iconColor!: string;

	@Prop({type: String, default: ''})
	public iconBgColor!: string;

	@Prop({type: Number, default: 40})
	public avatarSize!: number | null;

	@Prop({type: String, default: null})
	public imageUrl!: string | null;

	@Prop({type: Boolean, default: false})
	public highlighted!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public unwrapped!: boolean;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	@Prop({type: Boolean, default: false})
	public flat!: boolean;

	private get titleBg(): string {
		if (this.titleBgColor.length === 0) {
			return this.highlighted ? 'black' : 'grey lighten-4'
		}

		return this.titleBgColor;
	}

	private get iconBg(): string {
		if (this.iconBgColor.length === 0) {
			return this.highlighted ? 'secondary' : 'grey';
		}

		return this.iconBgColor;
	}

	private get loadingValue(): string | null {
		return this.loading ? 'secondary' : null;
	}

}
