import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VTextField} from "vuetify/lib";

const textField = VTextField as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiTextField',
	extends: textField,
	props: {
		hideDetails: {
			type: String,
			default: 'auto',
		},
		filled: {
			type: Boolean,
			default: true,
		},
	},
});
