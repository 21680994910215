



































































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import WbzoLogo from "@/components/WbzoLogo.vue";
import {Icon} from "@/helpers/icons";

@Component({
	components: {WbzoLogo}
})
export default class App extends Vue {
	private icons = Icon;
}
